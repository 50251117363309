import { PayloadAction, Slice, createSlice, current } from "@reduxjs/toolkit";
import { filterQuestionInCategory } from "utilities/redux/questions";
import { AccountType, AccountWithCategoriesType, PlayloadQuestionInCategoryType } from "utilities/redux/types";

export type QuestionToProcessSliceType = {
  accountsInView: number[];
  categoriesInView: number[];
  search: string;
  questions: AccountWithCategoriesType[];
};

const initialState: QuestionToProcessSliceType = {
  accountsInView: [],
  categoriesInView: [],
  search: "",
  questions: [],
};

export const QuestionToProcessSlice: Slice<QuestionToProcessSliceType> = createSlice({
  name: "questionInWaitingData",
  initialState,
  reducers: {
    setCategories: (state, action: PayloadAction<AccountWithCategoriesType[]>) => {
      state.questions = action.payload;
    },
    setQuestions: (state, action: PayloadAction<{ categoryIndex: number; account: AccountType }>) => {
      const accountIndex = state.questions[action.payload.categoryIndex].accounts.findIndex(
        (item) => item.id === action.payload?.account?.id,
      );

      if (accountIndex !== -1) {
        state.questions = [
          ...state.questions.slice(0, action.payload.categoryIndex),
          {
            ...state.questions[action.payload.categoryIndex],
            accounts: [
              ...state.questions[action.payload.categoryIndex].accounts.slice(0, accountIndex),
              {
                ...state.questions[action.payload.categoryIndex].accounts[accountIndex],
                ...action.payload.account,
              },
              ...state.questions[action.payload.categoryIndex].accounts.slice(accountIndex + 1),
            ],
          },
          ...state.questions.slice(action.payload.categoryIndex + 1),
        ];
      }
    },

    processQuestion: (state, action: PayloadAction<PlayloadQuestionInCategoryType>) => {
      const { categoryIndex, accountIndex, questionGroupIndex, questionIndex } = action.payload;

      let clonedQuestions = current(state.questions) as any[];
      const temp = filterQuestionInCategory(
        clonedQuestions,
        categoryIndex,
        accountIndex,
        questionGroupIndex,
        questionIndex,
      );
      state.questions = temp.questions;
    },
    resetToProcess: (state, action: PayloadAction<null>) => {
      state.questions = [];
    },
    addAccountInView: (state, action: PayloadAction<number>) => {
      state.accountsInView = [...state.accountsInView, action.payload];
    },
    removeAccountInView: (state, action: PayloadAction<number>) => {
      state.accountsInView = state.accountsInView.filter((item) => action.payload !== item);
    },
    addCategoryInView: (state, action: PayloadAction<number>) => {
      state.categoriesInView = [...state.categoriesInView, action.payload];
    },
    removeCategoryInView: (state, action: PayloadAction<number>) => {
      state.categoriesInView = state.categoriesInView.filter((item) => action.payload !== item);
    },
    setSearchAccount: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
  },
});

export const {
  setQuestions,
  processQuestion,
  resetToProcess,
  setSearchAccount,
  setCategories,
  addCategoryInView,
  removeAccountInView,
  addAccountInView,
  removeCategoryInView,
} = QuestionToProcessSlice.actions;

export default QuestionToProcessSlice.reducer;
