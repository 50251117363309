import { PayloadAction, Slice, createSlice } from "@reduxjs/toolkit";
import {
  AccountingFirmBillingInformationType,
  AccountingFirmConnectorType,
  AccountingFirmConnectorTypeType,
  AccountingFirmType,
  CompanyType,
  UserType,
} from "utilities/redux/types";

type AccountingFirmDataType = {
  accountingFirm: AccountingFirmType | null;
  accountingFirmBillingInformations: AccountingFirmBillingInformationType;
  usersCount: number | undefined;
  entriesImportsCount: number | undefined;
  companiesCount: number | undefined;
  questionsCount: number | undefined;
  collaborators: UserType[];
  connectors: AccountingFirmConnectorType[];
  connnectorAvailables: AccountingFirmConnectorTypeType[];
  connnectorAvailableSelected: AccountingFirmConnectorTypeType | null;
  connectorSelected: AccountingFirmConnectorType;
  picture: any;
  mustEnterPM: boolean;
  mustEnterPMModal: boolean;
};

const initialState: AccountingFirmDataType = {
  accountingFirmBillingInformations: null,
  accountingFirm: null,
  usersCount: undefined,
  entriesImportsCount: undefined,
  companiesCount: undefined,
  questionsCount: undefined,
  collaborators: [],
  connnectorAvailables: [],
  connectors: [],
  connectorSelected: null,
  connnectorAvailableSelected: null,
  picture: null,
  // alert no ayment
  mustEnterPM: false,
  mustEnterPMModal: false,
};

export const AccountingFirmSlice: Slice<AccountingFirmDataType> = createSlice({
  name: "accountingFirmData",
  initialState,
  reducers: {
    setMustEnterPM: (state, action: PayloadAction<boolean>) => {
      state.mustEnterPM = action.payload;
    },
    setMustEnterPMModal: (state, action: PayloadAction<boolean>) => {
      state.mustEnterPMModal = action.payload;
    },
    setAccountingFirm: (state, action: PayloadAction<AccountingFirmType>) => {
      state.accountingFirm = action.payload;
    },
    setAccountingFirmBillingInformations: (state, action: PayloadAction<AccountingFirmBillingInformationType>) => {
      state.accountingFirmBillingInformations = action.payload;
    },
    setUsersCount: (state, action: PayloadAction<number>) => {
      state.usersCount = action.payload;
    },
    setEntriesImportsCount: (state, action: PayloadAction<number>) => {
      state.entriesImportsCount = action.payload;
    },
    setCompaniesCount: (state, action: PayloadAction<number>) => {
      state.companiesCount = action.payload;
    },
    setQuestionsCount: (state, action: PayloadAction<number>) => {
      state.questionsCount = action.payload;
    },
    setConnectors: (state, action: PayloadAction<AccountingFirmConnectorType[]>) => {
      state.connectors = action.payload;

      if (state.connectorSelected?.accountingFirmConnectorType) {
        const updatedConnectorSelected = action.payload.find(
          (c) => c.accountingFirmConnectorType.value === state.connectorSelected.accountingFirmConnectorType.value,
        );

        state.connectorSelected = updatedConnectorSelected;
      }
    },

    setConnnectorAvailables: (state, action: PayloadAction<AccountingFirmConnectorTypeType[]>) => {
      state.connnectorAvailables = action.payload;
    },
    setConnectorSelected: (state, action: PayloadAction<AccountingFirmConnectorType>) => {
      state.connectorSelected = action.payload;
    },

    updateConnector: (state, action: PayloadAction<AccountingFirmConnectorType>) => {
      const index = state.connectors?.findIndex((c) => c.id === action.payload?.id);

      if (index !== -1) {
        state.connectors = [...state.connectors.slice(0, index), action.payload, ...state.connectors.slice(index + 1)];
      }

      if (action.payload?.id === state.connectorSelected?.id) {
        state.connectorSelected = action?.payload;
      }
    },
    updateDefaultConnector: (state, action: PayloadAction<AccountingFirmConnectorTypeType>) => {
      if (state.accountingFirm) {
        let copy = state.accountingFirm;
        copy.defaultConnector = action.payload;
        state.accountingFirm = copy;
      }
    },
    setCollaborators: (state, action: PayloadAction<UserType[]>) => {
      state.collaborators = action.payload;
    },

    updateCollaborator: (state, action: PayloadAction<UserType>) => {
      const collaboratorIndex = state?.collaborators?.findIndex((user) => user?.id === action?.payload?.id);

      if (collaboratorIndex !== -1) {
        state.collaborators = [
          ...state?.collaborators?.slice(0, collaboratorIndex),
          { ...action?.payload },
          ...state?.collaborators?.slice(collaboratorIndex + 1),
        ];
      }
    },

    addCollaborator: (state, action: PayloadAction<{ collaborator: UserType; }>) => {
      let collaboratorsCopy = JSON.parse(JSON.stringify(state.collaborators));

      collaboratorsCopy.push(action.payload.collaborator);

      state.collaborators = collaboratorsCopy;
    },

    deleteCollaborator: (state, action: PayloadAction<{ collaboratorId: number; }>) => {
      let collaboratorsCopy = JSON.parse(JSON.stringify(state.collaborators));

      collaboratorsCopy = collaboratorsCopy.filter(
        (collaborator: UserType) => collaborator.id !== action.payload.collaboratorId,
      );

      state.collaborators = collaboratorsCopy;
    },
    removeAffectation: (state, action: PayloadAction<{ collaboratorSelected: UserType; company: CompanyType; }>) => {
      let temp = JSON.parse(JSON.stringify(state.collaborators));
      const collaboratorIndex = temp.findIndex(
        (collab: UserType) => collab.email === action.payload.collaboratorSelected.email,
      );

      temp[collaboratorIndex].companiesAffected = temp[collaboratorIndex].companiesAffected.filter(
        (item: CompanyType) => item.slug !== action.payload.company.slug,
      );

      state.collaborators = temp;
    },
    addAffectation: (state, action: PayloadAction<{ collaboratorSelected: UserType; company: CompanyType; }>) => {
      let temp = JSON.parse(JSON.stringify(state.collaborators));
      const collaboratorIndex = temp.findIndex(
        (collab: UserType) => collab.email === action.payload.collaboratorSelected.email,
      );

      temp[collaboratorIndex].companiesAffected.push(action.payload.company);

      state.collaborators = temp;
    },
    updateAccountingFirmPicture: (state, action: PayloadAction<any>) => {
      state.picture = action.payload;
      if (state.accountingFirm !== null) state.accountingFirm = { ...state.accountingFirm, isPictureDefined: true };
    },
  },
});

export const {
  setAccountingFirm,
  setAccountingFirmBillingInformations,
  setCollaborators,
  setConnectors,
  setConnnectorAvailables,
  setConnectorAvailableSelected,
  setConnectorSelected,
  updateConnector,
  updateDefaultConnector,
  createNewConnector,
  addCollaborator,
  updateCollaborator,
  setUsersCount,
  setCompaniesCount,
  setEntriesImportsCount,
  setQuestionsCount,
  deleteCollaborator,
  removeAffectation,
  addAffectation,
  updateAccountingFirmPicture,
  setMustEnterPM,
  setMustEnterPMModal,
} = AccountingFirmSlice.actions;

export default AccountingFirmSlice.reducer;
