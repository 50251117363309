import { PayloadAction, Slice, createSlice, current } from "@reduxjs/toolkit";
import { filterQuestionInCategory, updateFilteredCategory } from "utilities/redux/questions";
import {
  AccountType,
  AccountWithCategoriesType,
  PlayloadQuestionInCategoryType,
  UserType,
} from "utilities/redux/types";

type SchedulerReminderType = { id: number; comment: string; date: string; };

export type QuestionToSendSliceType = {
  accountsInView: number[];
  categoriesInView: number[];
  search: string;
  questions: AccountWithCategoriesType[];
  expirationDate: string;
  comment: string;
  showSender: boolean;
  isOpenSendModal: boolean;
  isOpenPreviewModal: boolean;
  accountingFirmRecipients: UserType[];
  companyRecipients: UserType[];
  includeMySelfDuplicata: boolean;
  activatePDF: boolean;
  includeWord: boolean;
  scheduledReminders: SchedulerReminderType[];
};

const initialState: QuestionToSendSliceType = {
  accountsInView: [],
  categoriesInView: [],
  search: "",
  questions: [],
  expirationDate: null,
  isOpenSendModal: false,
  isOpenPreviewModal: false,
  includeMySelfDuplicata: false,
  accountingFirmRecipients: [],
  companyRecipients: [],
  comment: "",
  showSender: false,
  activatePDF: true,
  includeWord: false,
  scheduledReminders: [],
};

export const QuestionToSendSlice: Slice<QuestionToSendSliceType> = createSlice({
  name: "questionToSendData",
  initialState,
  reducers: {
    setCategories: (state, action: PayloadAction<AccountWithCategoriesType[]>) => {
      state.questions = action.payload;
    },
    setQuestions: (state, action: PayloadAction<{ categoryIndex: number; account: AccountType; }>) => {
      const accountIndex = state.questions[action.payload.categoryIndex].accounts.findIndex(
        (item) => item.id === action.payload?.account?.id,
      );

      if (accountIndex !== -1) {
        state.questions = [
          ...state.questions.slice(0, action.payload.categoryIndex),
          {
            ...state.questions[action.payload.categoryIndex],
            accounts: [
              ...state.questions[action.payload.categoryIndex].accounts.slice(0, accountIndex),
              {
                ...state.questions[action.payload.categoryIndex].accounts[accountIndex],
                ...action.payload.account,
              },
              ...state.questions[action.payload.categoryIndex].accounts.slice(accountIndex + 1),
            ],
          },
          ...state.questions.slice(action.payload.categoryIndex + 1),
        ];
      }
    },
    addAccountInView: (state, action: PayloadAction<number>) => {
      state.accountsInView = [...state.accountsInView, action.payload];
    },
    removeAccountInView: (state, action: PayloadAction<number>) => {
      state.accountsInView = state.accountsInView.filter((item) => action.payload !== item);
    },
    addCategoryInView: (state, action: PayloadAction<number>) => {
      state.categoriesInView = [...state.categoriesInView, action.payload];
    },
    removeCategoryInView: (state, action: PayloadAction<number>) => {
      state.categoriesInView = state.categoriesInView.filter((item) => action.payload !== item);
    },
    setSearchAccount: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setIncludeWord: (state, action: PayloadAction<boolean>) => {
      state.includeWord = action.payload;
    },

    modifyQuestion: (state, action: PayloadAction<PlayloadQuestionInCategoryType>) => {
      const { categoryIndex, accountIndex, questionGroupIndex, questionIndex } = action.payload;

      let clonedQuestions = current(state.questions) as any[];
      const temp = filterQuestionInCategory(
        clonedQuestions,
        categoryIndex,
        accountIndex,
        questionGroupIndex,
        questionIndex,
      );

      state.questions = temp.questions;
    },

    unValidateAccount: (state, action) => {
      const { accountId, categoryId } = action.payload;

      let copyQuestions = current(state.questions);

      let categoryIndexFiltered = copyQuestions?.findIndex((cate) => cate.id == categoryId);

      let copyAccounts = current(state.questions[categoryIndexFiltered].accounts);
      let filteredAccounts = copyAccounts.filter((account) => account.id != accountId);
      state.questions[categoryIndexFiltered].accounts = filteredAccounts;
    },
    modifyCategory: (state, action: PayloadAction<number>) => {
      state.questions = updateFilteredCategory(state.search, action.payload, state.questions);
    },
    archiveQuestion: (state, action: PayloadAction<PlayloadQuestionInCategoryType>) => {
      const { categoryIndex, accountIndex, questionGroupIndex, questionIndex } = action.payload;

      const temp = filterQuestionInCategory(
        state.questions,
        categoryIndex,
        accountIndex,
        questionGroupIndex,
        questionIndex,
      );
      state.questions = temp?.questions ?? [];
    },
    archiveCategory: (state, action: PayloadAction<number>) => {
      state.questions = updateFilteredCategory(state.search, action.payload, state.questions);
    },
    sendAllQuestions: (state, action: PayloadAction) => {
      state.questions = [];
    },

    //// SENDING PARAMETERS ///////////////////////////

    setOpenSendModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenSendModal = action.payload;
    },
    setOpenPreviewModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenPreviewModal = action.payload;
    },
    setAccountingFirmRecipients: (state, action: PayloadAction<UserType[]>) => {
      state.accountingFirmRecipients = action.payload;
    },
    setCompanyRecipients: (state, action: PayloadAction<UserType[]>) => {
      state.companyRecipients = action.payload;
    },
    setIncludeMySelfDuplicata: (state, action: PayloadAction<boolean>) => {
      state.includeMySelfDuplicata = action.payload;
    },

    // global parameters //////
    setComment: (state, action: PayloadAction<string>) => {
      state.comment = action.payload;
    },
    setShowSender: (state, action: PayloadAction<boolean>) => {
      state.showSender = action.payload;
    },
    setExpirationDate: (state, action: PayloadAction<string>) => {
      state.expirationDate = action.payload;
    },
    setActivatePDF: (state, action: PayloadAction<boolean>) => {
      state.activatePDF = action.payload;
    },
    setSchedulerReminders: (state, action: PayloadAction<SchedulerReminderType[]>) => {
      state.scheduledReminders = action.payload;
    },

    //////

    resetSend: (state, action: PayloadAction<null>) => {
      state.questions = [];
    },
  },
});

export const {
  setCategories,
  addAccountInView,
  removeAccountInView,
  addCategoryInView,
  removeCategoryInView,
  setSearchAccount,
  setActivatePDF,
  setSchedulerReminders,
  unValidateAccount,
  setQuestions,
  modifyQuestion,
  modifyCategory,
  archiveQuestion,
  archiveCategory,
  sendAllQuestions,
  setOpenSendModal,
  setOpenPreviewModal,
  setComment,
  setShowSender,
  setExpirationDate,
  setIncludeWord,
  resetSend,
  setIncludeMySelfDuplicata,
  setCompanyRecipients,
  setAccountingFirmRecipients,
} = QuestionToSendSlice.actions;

export default QuestionToSendSlice.reducer;
