import { EntryExerciceType, EntryGraphAccountTypeType, EntryGraphType } from "../../../../utilities/redux/types";
import { diffDays } from "../../../../utilities/tools/date";
import { BalanceType } from "../AccountingChart";

export default function shapeData(data: Array<{
  graph: EntryGraphType,
  exercices: Array<{
    exercice: EntryExerciceType,
    values: Array<{
      type: EntryGraphAccountTypeType,
      balances: BalanceType[];
    }>;
  }>;
}>, maxNbExercice: number | null = null) {
  let graphs = data;

  if (maxNbExercice) {
    graphs = graphs?.map(g => {
      const exerciceStarts = [... new Set(g.exercices.map(e => e.exercice.startAt))]
        .sort((a, b) => diffDays(a, b));

      const keepedExerciceStart = exerciceStarts.slice(-maxNbExercice);

      return {
        ...g,
        exercices: g.exercices.filter(e => keepedExerciceStart.includes(e.exercice.startAt)),
      };
    });
  }


  return graphs?.sort((a, b) => {
    if (a.graph.graphType.organization && b.graph.graphType.organization) {
      return a.graph.graphType.organization - b.graph.graphType.organization;
    } else if (a.graph.graphType.organization) { return 1; } else { return -1; }
  }) ?? [];
}