import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import { CriteriaType } from "ui/components/inputs";
import { isCompanyFromACD, isCompanyFromMyUnisoft, isCompanyFromQuadra } from "utilities/redux/typeGuards";
import { AccountingFirmConnectorType, AccountingFirmConnectorTypeType, MixCompanyType } from "utilities/redux/types";

type MixCompaniesType = MixCompanyType[];

export type ImportCompanyType = {
  companies: MixCompaniesType;
  allcompanies: MixCompaniesType;
  companiesSelected: MixCompanyType[];
  searchItem: MixCompanyType;
  searchTerms: string;
  criteria: CriteriaType | null;
  countPages: number;
  itemsPerPage: number;
  currentPage: number;
  import_multiple_step: number;
  connectorSelected: AccountingFirmConnectorType;
};

const initialState: ImportCompanyType = {
  import_multiple_step: 0,
  // COMPANIES
  allcompanies: [],
  companies: [],
  companiesSelected: [],
  // PAGINATION
  itemsPerPage: 50,
  countPages: 1,
  currentPage: 1,

  // SEARCH
  searchTerms: "",
  searchItem: null,
  criteria: null,
  //  CONNECTOR
  connectorSelected: null,
};

const cbConnector = (item: MixCompanyType, payload, connectorSelected: AccountingFirmConnectorTypeType) => {
  if (isCompanyFromACD(item, connectorSelected.value)) {
    return item.Code == payload.Code;
  }
  if (isCompanyFromMyUnisoft(item, connectorSelected.value)) {
    return item.society_id == payload.society_id;
  }
  if (isCompanyFromQuadra(item, connectorSelected.value)) {
    return item.Code == payload.Code;
  }
};

export const ImportCompanyFromApi: Slice<ImportCompanyType> = createSlice({
  name: "importCompanyData",
  initialState,

  reducers: {
    resetState: (state, action: PayloadAction<null>) => {
      (state.import_multiple_step = 0),
        (state.allcompanies = []),
        (state.companies = []),
        (state.companiesSelected = []),
        // PAGINATION
        (state.itemsPerPage = 50),
        (state.countPages = 1),
        (state.currentPage = 1),
        // SEARCH
        (state.searchTerms = ""),
        (state.searchItem = null),
        (state.criteria = null),
        //  CONNECTOR
        (state.connectorSelected = null);
    },
    setConnectorSelected: (state, action: PayloadAction<AccountingFirmConnectorType>) => {
      state.connectorSelected = action.payload;
    },
    setMultipleImportStep: (state, action: PayloadAction<number>) => {
      state.import_multiple_step = action.payload;
    },
    storeAllCompanies: (state, action: PayloadAction<MixCompaniesType>) => {
      state.allcompanies = action.payload;

      let nonSelectedCompanies = action.payload.filter((item: any) => {
        return !state.companiesSelected.some((item2: MixCompanyType) => {
          return cbConnector(item, item2, state.connectorSelected.accountingFirmConnectorType);
        });
      });

      let finalArray = nonSelectedCompanies.map((item) => {
        return { ...item, restricted: false };
      });

      // let paginated = paginateData(finalArray, state.itemsPerPage, state.currentPage);

      state.companies = finalArray;
    },

    // MULTI FIRST STEP ///
    selectCompanies: (state, action: PayloadAction<MixCompanyType>) => {
      let copy = JSON.parse(JSON.stringify(state.companiesSelected));

      if (
        state.companiesSelected.some((item) =>
          cbConnector(item, action.payload, state.connectorSelected.accountingFirmConnectorType),
        )
      ) {
        ////////////// A. FILTER IT

        let result = copy.filter(
          (item: MixCompanyType) =>
            !cbConnector(item, action.payload, state.connectorSelected.accountingFirmConnectorType),
        );

        state.companiesSelected = result;
        // and add into companies
        let allComp = JSON.parse(JSON.stringify(state.allcompanies));
        let filtered = allComp.filter(
          (item: any) =>
            !result.some((item2: any) => cbConnector(item2, item, state.connectorSelected.accountingFirmConnectorType)),
        );

        state.companies = filtered;
      } else {
        /////////////  B. NOT IN ARRAY  ->ADD IT
        let copy2 = JSON.parse(JSON.stringify(state.companiesSelected));
        let newSelected = [...copy2, action.payload];
        state.companiesSelected = newSelected;
        // and remove into companies
        let copyFromCompanies = JSON.parse(JSON.stringify(state.allcompanies));
        let result = copyFromCompanies.filter(
          (item: MixCompanyType) =>
            !newSelected.some((item2) => cbConnector(item2, item, state.connectorSelected.accountingFirmConnectorType)),
        );

        state.companies = result;
      }
    },
    updateRestrictionCompany: (state, action: PayloadAction<{ company: MixCompanyType; restricted: boolean }>) => {
      const copy = JSON.parse(JSON.stringify(state.companiesSelected));
      let uniqId = null;
      if (isCompanyFromACD(action.payload.company, state.connectorSelected?.accountingFirmConnectorType.value)) {
        uniqId = action.payload?.company?.Code;
      }

      if (copy?.length > 0) {
        let selected = copy.find((item: MixCompanyType) =>
          cbConnector(item, action.payload.company, state.connectorSelected.accountingFirmConnectorType),
        );
        selected.restricted = action.payload.restricted;
      }

      state.companiesSelected = copy;
    },

    resetCompanySelected: (state, action: PayloadAction<null>) => {
      state.companiesSelected = [];

      state.companies = state.allcompanies;
    },
    /// -> PAGINATION

    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;

      if (state.companies.length == 0) {
        state.currentPage = 1;
      }
    },
    setCountPages: (state, action: PayloadAction<number>) => {
      state.countPages = action.payload;
    },

    ///// SEARCH BY CODE
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerms = action.payload;
    },
    setCriteria: (state, action: PayloadAction<CriteriaType>) => {
      state.criteria = action.payload;
    },

    setSearchItem: (state, action: PayloadAction<MixCompanyType>) => {
      state.searchItem = action.payload;
    },
  },
});
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const {
  setConnectorSelected,
  resetState,
  storeAllCompanies,

  selectCompanies,
  setMultipleImportStep,
  setCurrentPage,
  setCountPages,
  updateRestrictionCompany,
  resetCompanySelected,
  setSearchItem,
  setSearchTerm,
  setCriteria,
} = ImportCompanyFromApi.actions;

export default ImportCompanyFromApi.reducer;
