import { PayloadAction, Slice, createSlice, current } from "@reduxjs/toolkit";
import {
  AccountType,
  EntryExerciceType,
  PlayloadQuestionAccountType,
  PlayloadQuestionGroupType,
  PlayloadQuestionType,
  QuestionCategoryType,
  QuestionExpertAttachmentType,
  QuestionGroupType,
} from "utilities/redux/types";

import {
  addQuestionInCategory,
  filterAccount,
  filterQuestion,
  filterQuestionGroup,
  substractQuestionInCategory,
  updateFilteredQuestion,
} from "utilities/redux/questions";

type PartialLetteringQuestionType = {
  questionId: number;
  questionIndex: number;
  questionGroupIndex: number;
  accountIndex: number;
};

export type QuestionToValidatedSliceType = {
  accountSelected: number;
  accountsInView: number[];
  categorySelected: number | null;
  categories: QuestionCategoryType[];
  search: string;
  questions: AccountType[];
  newAccount: AccountType | null;
  exerciceSelected: EntryExerciceType | null;
  newAccountStep: string;
  partialLetteringQuestions: PartialLetteringQuestionType[];
  partialLetteringAccountSelected: number;
};

const initialState: QuestionToValidatedSliceType = {
  accountSelected: null,
  accountsInView: [],
  categorySelected: null,
  categories: [],
  search: "",
  questions: [],
  newAccount: null,
  newAccountStep: "choose",
  exerciceSelected: null,
  partialLetteringQuestions: [],
  partialLetteringAccountSelected: null,
};

export const QuestionToValidatedSlice: Slice<QuestionToValidatedSliceType> = createSlice({
  name: "questionToValidatedData",
  initialState,
  reducers: {
    setCategories: (state, action: PayloadAction<QuestionCategoryType[]>) => {
      let clickables = action.payload?.filter((category) => category?.question_nbr !== 0);
      let nonClickables = action.payload?.filter((category) => category?.question_nbr === 0);

      state.categories = [...clickables, ...nonClickables];

      const categoriesCopy = JSON.parse(JSON.stringify(state.categories));

      state.categorySelected = categoriesCopy[0].id || null;
    },
    setAccounts: (state, action: PayloadAction<AccountType[]>) => {
      state.questions = action.payload;
    },
    setAccountSelected: (state, action: PayloadAction<number>) => {
      state.accountSelected = action.payload;
    },
    addAccountInView: (state, action: PayloadAction<number>) => {
      state.accountsInView = [...state.accountsInView, action.payload];
    },
    removeAccountInView: (state, action: PayloadAction<number>) => {
      state.accountsInView = state.accountsInView.filter((item) => action.payload !== item);
    },
    setQuestions: (state, action: PayloadAction<AccountType>) => {
      const accountIndex = state.questions.findIndex((item) => item.id === action.payload.id);

      if (accountIndex !== -1) {
        state.questions = [
          ...state.questions.slice(0, accountIndex),
          { ...state.questions[accountIndex], ...action.payload },
          ...state.questions.slice(accountIndex + 1),
        ];
      }
    },

    setSearchAccount: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },

    setSelectedCategory: (state, action: PayloadAction<number>) => {
      state.categorySelected = action.payload;
      state.questions = [];
    },

    // UPDATE VIEW /////////////////////////////////////
    /// Account ///////////////////////////
    selectExercice(state, action: PayloadAction<EntryExerciceType>) {
      state.exerciceSelected = action.payload;
    },

    addAccount(state, action: PayloadAction<{ code: string; legend: string; id: number }>) {
      const { code, legend, id } = action.payload;

      const newAccount: AccountType = {
        id: id,
        code: code,
        legend: legend,
        questions_nb: 0,
      };

      state.newAccount = newAccount;
      state.newAccountStep = "questions";
    },

    resetAccount(state) {
      state.newAccount = null;
      state.newAccountStep = "choose";
    },

    persistNewAccount(state, action: PayloadAction<QuestionGroupType>) {
      const temp = JSON.parse(JSON.stringify(state.questions));

      temp.unshift({
        ...state.newAccount,
        questions_nb: action.payload?.questions?.length,
        questionGroups: [action.payload],
      });

      state.categories = addQuestionInCategory(
        state.categorySelected,
        state.categories,
        action.payload?.questions.length,
      );

      state.newAccount = null;
      state.questions = temp;
      state.newAccountStep = "choose";
    },

    validateCategory(state, action: PayloadAction<number>) {
      state.categories = substractQuestionInCategory(state.categorySelected, state.categories, action.payload);

      state.questions = updateFilteredQuestion(state.search, state.questions);
    },
    archiveCategory(state, action: PayloadAction<number>) {
      state.categories = substractQuestionInCategory(state.categorySelected, state.categories, action.payload);

      state.questions = updateFilteredQuestion(state.search, state.questions);
    },

    validateAccount(state, action: PayloadAction<PlayloadQuestionAccountType>) {
      const { accountIndex } = action.payload;

      const temp = current(state.questions);

      let nbrOfQuestions = 0;
      temp[accountIndex].questionGroups.forEach((questionGroup: QuestionGroupType) =>
        questionGroup.questions.forEach((question) => (nbrOfQuestions += 1)),
      );

      state.categories = substractQuestionInCategory(state.categorySelected, state.categories, nbrOfQuestions);

      const newAccounts = filterAccount(state.questions, accountIndex);

      state.questions = newAccounts.questions;
    },
    archiveAccount(state, action: PayloadAction<PlayloadQuestionAccountType>) {
      const { accountIndex } = action.payload;

      const temp = JSON.parse(JSON.stringify(state.questions));

      let nbrOfQuestions = 0;
      temp[accountIndex].questionGroups.forEach((questionGroup: QuestionGroupType) =>
        questionGroup.questions.forEach((question) => (nbrOfQuestions += 1)),
      );

      state.categories = substractQuestionInCategory(state.categorySelected, state.categories, nbrOfQuestions);

      const newAccounts = filterAccount(state.questions, accountIndex);

      state.questions = newAccounts.questions;
    },

    setPartialLetteringAccountSelected(state, action: PayloadAction<number>) {
      state.partialLetteringAccountSelected = action.payload;
    },

    selectQuestionPartialLettering(state, action: PayloadAction<PartialLetteringQuestionType>) {
      let copy = JSON.parse(JSON.stringify(state.partialLetteringQuestions));

      if (copy?.some((item) => item.questionId == action.payload.questionId)) {
        let copy2 = copy.filter((item) => item.questionId != action.payload.questionId);

        state.partialLetteringQuestions = copy2;
      } else {
        state.partialLetteringQuestions = [...state.partialLetteringQuestions, action.payload];
      }
    },
    resetQuestionsPartialLettering(state, action: PayloadAction<null>) {
      state.partialLetteringQuestions = [];
    },

    /// Question Group ///////////////////////////

    persistNewQuestionGroup(state, action: PayloadAction<{ accountIndex: number; questionGroup: QuestionGroupType }>) {
      const { accountIndex, questionGroup } = action.payload;
      const temp = JSON.parse(JSON.stringify(state.questions));

      temp[accountIndex].questionGroups.push(questionGroup);
      temp[accountIndex].questions_nb = temp[accountIndex].questions_nb + questionGroup.questions.length;

      state.categories = addQuestionInCategory(
        state.categorySelected,
        state.categories,
        questionGroup.questions.length,
      );

      state.questions = temp;
    },

    updateQuestionGroupExercice(state, action: PayloadAction<any>) {
      const { accountIndex, questionGroupIndex, exercice } = action.payload;
      const temp = JSON.parse(JSON.stringify(state.questions));
      temp[accountIndex].questionGroups[questionGroupIndex].exercice = exercice;
      state.questions = temp;
    },
    updateQuestionGroupTitle(state, action: PayloadAction<any>) {
      const { accountIndex, questionGroupIndex, newTitle } = action.payload;
      const temp = JSON.parse(JSON.stringify(state.questions));
      temp[accountIndex].questionGroups[questionGroupIndex].announcement = newTitle;
      state.questions = temp;
    },
    updateAccountLegend(state, action: PayloadAction<{ accountIndex: number; newTitle: string }>) {
      const { accountIndex, newTitle } = action.payload;
      const temp = JSON.parse(JSON.stringify(state.questions));
      temp[accountIndex].legend = newTitle;
      state.questions = temp;
    },

    archiveQuestionGroup(state, action: PayloadAction<PlayloadQuestionGroupType>) {
      const { accountIndex, questionGroupIndex } = action.payload;

      let clonedQuestions = current(state.questions) as any[];

      state.categories = substractQuestionInCategory(
        state.categorySelected,
        state.categories,
        clonedQuestions[accountIndex].questionGroups[questionGroupIndex].questions?.length,
      );

      const result = filterQuestionGroup(clonedQuestions as any[], accountIndex, questionGroupIndex);

      state.questions = result.questions;
    },

    validateQuestionGroup(state, action: PayloadAction<PlayloadQuestionGroupType>) {
      const { accountIndex, questionGroupIndex } = action.payload;
      const temp = JSON.parse(JSON.stringify(state.questions)) as any[];

      state.categories = substractQuestionInCategory(
        state.categorySelected,
        state.categories,
        temp[accountIndex].questionGroups[questionGroupIndex].questions.length,
      );

      const result = filterQuestionGroup(state.questions, accountIndex, questionGroupIndex);

      state.questions = result.questions;
    },

    /// Question /////////////////////////////

    addQuestion(state, action: PayloadAction<any>) {
      const { accountIndex, questionGroupIndex } = action.payload;

      const temp = JSON.parse(JSON.stringify(state.questions));
      const newQuestion = {
        answer: "",
        content: "",
        id: null,
        answerDeniedExplanation: "",
      };
      temp[accountIndex].questionGroups[questionGroupIndex].questions.push(newQuestion);
      temp[accountIndex].questions_nb = temp[accountIndex].questions_nb + 1;
      state.questions = temp;

      state.categories = addQuestionInCategory(state.categorySelected, state.categories, 1);
    },
    // attachment
    addQuestionPreview(state, action: PayloadAction<any>) {
      const { file, accountIndex, questionGroupIndex, questionIndex } = action.payload;
      let temp = JSON.parse(JSON.stringify(state.questions));

      temp[accountIndex].questionGroups[questionGroupIndex].questions[questionIndex].questionExpertAttachments.push(
        file,
      );
      state.questions = temp;
    },
    deleteQuestionPreview(state, action: PayloadAction<any>) {
      const { accountIndex, questionGroupIndex, questionIndex, attachmentId } = action.payload;
      let temp = JSON.parse(JSON.stringify(state.questions));

      temp[accountIndex].questionGroups[questionGroupIndex].questions[questionIndex].questionExpertAttachments = temp[
        accountIndex
      ].questionGroups[questionGroupIndex].questions[questionIndex].questionExpertAttachments.filter(
        (attachment: QuestionExpertAttachmentType) => attachment?.id !== attachmentId,
      );
      state.questions = temp;
    },
    //

    persistNewQuestion(state, action: PayloadAction<any>) {
      const { accountIndex, questionGroupIndex, questionIndex, content } = action.payload;

      const temp = JSON.parse(JSON.stringify(state.questions));
      temp[accountIndex].questionGroups[questionGroupIndex].questions[questionIndex].content = content;
      temp[accountIndex].questions_nb = temp[accountIndex].questions_nb + 1;

      state.questions = temp;
    },

    addIdToQuestion(state, action: PayloadAction<any>) {
      const { accountIndex, questionGroupIndex, questionIndex, questionId, content } = action.payload;

      const temp = JSON.parse(JSON.stringify(state.questions));
      temp[accountIndex].questionGroups[questionGroupIndex].questions[questionIndex].id = questionId;
      temp[accountIndex].questionGroups[questionGroupIndex].questions[questionIndex].content = content;
      state.questions = temp;
    },

    updateQuestion(state, action: PayloadAction<any>) {
      const { accountIndex, questionGroupIndex, questionIndex, newContent } = action.payload;

      const temp = JSON.parse(JSON.stringify(state.questions));
      temp[accountIndex].questionGroups[questionGroupIndex].questions[questionIndex].content = newContent;
      state.questions = temp;
    },

    validateQuestion(state, action: PayloadAction<PlayloadQuestionType>) {
      const { accountIndex, questionGroupIndex, questionIndex } = action.payload;
      const temp = filterQuestion(state.questions, accountIndex, questionGroupIndex, questionIndex);

      state.categories = substractQuestionInCategory(state.categorySelected, state.categories, 1);
      state.questions = temp.questions;
    },

    //
    archiveQuestion(state, action: PayloadAction<PlayloadQuestionType>) {
      const { accountIndex, questionGroupIndex, questionIndex } = action.payload;
      let temp = filterQuestion(state.questions, accountIndex, questionGroupIndex, questionIndex);
      temp = JSON.parse(JSON.stringify(temp));

      state.categories = substractQuestionInCategory(state.categorySelected, state.categories, 1);

      state.questions = temp.questions;
    },

    setNewAccountStep: (state, action: PayloadAction<"choose" | "search" | "create" | "questions">) => {
      state.newAccountStep = action.payload;
    },

    addQuestionCategory: (state, action: PayloadAction<QuestionCategoryType>) => {
      const temp = JSON.parse(JSON.stringify(state.categories));
      temp.push(action.payload);

      state.categories = temp;
    },

    resetValidate: (state, action: PayloadAction<null>) => {
      state.questions = [];
      state.partialLetteringQuestions = [];
      state.partialLetteringAccountSelected = null;
    },
  },
});

export const {
  setAccountLoading,
  setCategories,
  resetQuestionsPartialLettering,
  selectQuestionPartialLettering,
  setPartialLetteringAccountSelected,
  validateCategory,
  setSearchAccount,

  setQuestions,
  setAccountSelected,
  addAccountInView,
  removeAccountInView,
  setAccounts,
  archiveCategory,
  setSelectedCategory,
  updateQuestionGroupExercice,
  updateQuestionGroupTitle,
  updateAccountLegend,
  persistNewQuestionGroup,
  addQuestion,
  addQuestionPreview,
  deleteQuestionPreview,
  addIdToQuestion,
  persistNewQuestion,
  validateQuestion,
  validateAccount,
  archiveAccount,
  validateQuestionGroup,
  archiveQuestion,
  archiveQuestionGroup,
  selectExercice,
  addAccount,
  resetAccount,
  persistNewAccount,
  setNewAccountStep,
  resetValidate,
  addQuestionCategory,
} = QuestionToValidatedSlice.actions;

export default QuestionToValidatedSlice.reducer;
