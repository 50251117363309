import { AccountingFirmConnectorTypeType, CompanyFromACDType, CompanyFromMyUnisoftType, CompanyFromQuadraType, MixCompanyType } from "./types";

/// TYPE GUARDS

export const isCompanyFromACD = (searchCompanyItem: MixCompanyType, connectorSelected: AccountingFirmConnectorTypeType["value"]): searchCompanyItem is CompanyFromACDType => {
  return connectorSelected === "acd";
};
export const isCompanyFromQuadra = (searchCompanyItem: MixCompanyType, connectorSelected: AccountingFirmConnectorTypeType["value"]): searchCompanyItem is CompanyFromQuadraType => {
  return connectorSelected === "quadra";
};
export const isCompanyFromMyUnisoft = (searchCompanyItem: MixCompanyType, connectorSelected: AccountingFirmConnectorTypeType["value"]): searchCompanyItem is CompanyFromMyUnisoftType => {
  return connectorSelected === "myunisoft";
};
////

// export const getUniqCodeFromCompany = (
//   company: MixCompanyType,
//   connectorTypeSelectedValue: AccountingFirmConnectorTypeType["value"],
// ): string => {
//   if (isCompanyFromACD(company, connectorTypeSelectedValue)) {
//     return company.Code || "";
//   }
//   if (isCompanyFromQuadra(company, connectorTypeSelectedValue)) {
//     return company.Code || "";
//   }
//   if (isCompanyFromMyUnisoft(company, connectorTypeSelectedValue)) {
//     return company.society_id?.toString() || "";
//   }
//   return "";
// };
